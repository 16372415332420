export const profileWriteUp =
  "Hello, I'm Joon Sunn, Foo. Welcome to my portfolio page.";

export const projectsList = [
  {
    title: "Events App",
    screenshot: "event-app.png",
    url: "https://event-app.joonsunn.com",
    description:
      "Events billboard with simple admin panel featuring full CRUD functionality.",
    techUsed: "MongoDB, Express, React, NodeJS, Material UI, Render.com",
    projectId: 10,
  },
  {
    title: "Bila Next Cuti",
    screenshot: "bila-next-cuti.png",
    url: "https://bila-next-cuti.joonsunn.com",
    description:
      "Web service that lets you check when is the next holiday based on selected state.",
    techUsed: "React, Next.js, Cloudflare Pages",
    projectId: 9,
  },
  {
    title: "Notes - Local",
    screenshot: "notes-local-vite.png",
    url: "https://notes-local-vite.joonsunn.com",
    description:
      "Web app that allows you to create, edit, and delete notes, and save them to local storage. Made as a PWA.",
    techUsed:
      "React, Typescript, Vite-PWA, IndexedDB (Dexie.js), Material UI, Docker, Netlify",
    projectId: 9,
  },
  {
    title: "PF Tools",
    screenshot: "pf-tools.png",
    url: "https://pf-tools.joonsunn.com",
    description:
      "Web app that contains multiple personal finance-related tools such as retirement funds calculator and compounding returns calculator.",
    techUsed: "Next.js, React, Typescript, Material UI, Docker, Vercel",
    projectId: 8,
  },
  {
    title: "Chattiest User",
    screenshot: "chattiest-user.png",
    url: "https://chattiest-user.joonsunn.com",
    description:
      "Web app to compute chattiest user in a chat room based on uploaded chatlog file.",
    techUsed: "Next.js, React, Javascript, Material UI, Docker, Jest, Vercel",
    projectId: 7,
  },
  {
    title: "Masak Apa?",
    screenshot: "masak-apa.png",
    url: "https://masak-apa.fly.dev/",
    description:
      "Web app to randomly choose what to cook based on chosen main ingredient.",
    techUsed:
      "React.js, express, node, Javascript, Postgresql, Bootstrap, Redux, React Router, Fly.io",
    projectId: 6,
  },
  {
    title: "Remittance Fintech Exchange Rate Checker",
    screenshot: "remit-checker.png",
    url: "https://remit-checker-fjs.fly.dev/",
    description:
      "Web app to compare exchange rates between several remittance fintech companies. Some reverse engineering of undocumented APIs were done to make this app.",
    techUsed: "React.js, express, node, Javascript, Fly.io",
    projectId: 4,
  },
  {
    title: "Investment Asset Tracker",
    screenshot: "asset-tracker.png",
    url: "https://asset-tracker-jsf.fly.dev/",
    description:
      "Web app to track investment portfolio consisting of the major financial assets. Some reverse engineering of undocumented APIs were done to make this app.",
    techUsed: "React.js, express, node, MongoDB, Javascript.",
    projectId: 3,
  },
  {
    title: "Portolio Page",
    screenshot: "portfolio-page.png",
    url: "https://joonsunn-portfolio-page.netlify.app/",
    description:
      "My portfolio page showcasing the personal projects that I've done. Has a search function to quickly filter projects based on keyword. The webpage has also incorporated responsive design that reflows screen elements depending on the viewport size and orientation.",
    techUsed: "React.js",
    projectId: 5,
  },
  {
    title: "Blog List",
    screenshot: "bloglist.png",
    url: "https://bloglist-jsf.fly.dev/",
    description:
      "Web app to record a list of favourite blogs, with function to like and delete entries.",
    techUsed: "React.js, express, node, MongoDB, Javascript.",
    projectId: 1,
  },
  {
    title: "Phonebook",
    screenshot: "phonebook.png",
    url: "https://fso-phonebook-jsf.fly.dev/",
    description:
      "Web app to record phone numbers, with function to add and delete entries.",
    techUsed: "React.js, express, node, MongoDB, Javascript.",
    projectId: 2,
  },
  // {
  // 	title: 'test6',
  // 	screenshot: 'test6.jpg',
  // 	url: 'github6.com',
  // 	description: 'description6',
  // 	techUsed: 'React.js, express, node, MongoDB',
  // 	projectId: 6,
  // }
];
