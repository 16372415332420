import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import useTheme from "@mui/material/styles";

export const OverallContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100%",
  gap: "5px",
}));

export const MainContainer = styled("main")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100%",
  gap: "15px",
}));
