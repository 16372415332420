import { Box, Button, Card, CardContent, Container } from "@mui/material";
import React from "react";
import { MainContainer } from "../../layouts/styles";
import { StyledCard } from "../../components/containers/styles";
import { PiTrafficConeDuotone } from "react-icons/pi";
import { useTheme } from "@mui/material/styles";

function About() {
  const theme = useTheme();
  return (
    <MainContainer>
      <div
        style={{
          top: "40%",
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "5rem",
        }}
      >
        <span>About page under construction.</span>
        <PiTrafficConeDuotone
          size="10rem"
          color={theme.palette.secondary.main}
        />
      </div>
    </MainContainer>
  );
}

export default About;
