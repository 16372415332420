import { Button } from "@mui/material";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ThemeSwitcher } from "../theme-switcher/ThemeSwitcher";
import { useTheme } from "@mui/material/styles";
import { StyledNav } from "./style";
import ActivePathButton from "../buttons/ActivePathButton";
import SocMedBox from "./SocMedBox";

function TopNavBar() {
  const theme = useTheme();
  const location = useLocation();
  // console.log(location);

  const checkActivePath = (locationPath) => {
    return location.pathname === locationPath;
  };

  const topNavButtonList = [
    { path: "/", buttonText: "Main" },
    { path: "/about", buttonText: "About" },
  ];

  return (
    <StyledNav>
      {/* <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        PLACEHOLDER FOR SOCIAL MEDIA
      </div> */}
      <SocMedBox />
      <div
        style={{
          display: "flex",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <ThemeSwitcher />
        {topNavButtonList.map((item, index) => (
          <ActivePathButton
            path={item.path}
            buttonText={item.buttonText}
            key={index}
          />
        ))}
      </div>
    </StyledNav>
  );
}

export default TopNavBar;
