import { Card, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import useTheme from "@mui/material/styles";

export const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100%",
  gap: "5px",
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: `2px 2px 15px 5px ${
    theme.palette.mode === "light" ? "lightgrey" : "black"
  }`,
  "&:hover": {
    boxShadow: `2px 2px 15px 5px ${
      // theme.palette.mode === "light" ? "lightgrey" : "black"
      theme.palette.secondary.main
    }`,
  },
  // outline: `1px solid ${theme.palette..main}`,
  // minWidth: "350px",
}));
