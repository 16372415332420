import { styled } from "@mui/material/styles";
import useTheme from "@mui/material/styles";

export const StyledFooter = styled("footer")(({ theme }) => ({
  bottom: 0,
  // position: "relative",
  paddingTop: "15px",
  fontSize: "0.7rem",
  textAlign: "center",
  width: "100%",
  // backgroundColor: theme.palette.background.paper,
  marginTop: "auto",
}));
