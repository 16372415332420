import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

function NotFound() {
  const theme = useTheme();
  return (
    <div>
      NotFound{" "}
      <Link to={"/"}>
        <div style={{ color: theme.palette.text.primary }}>Back to main</div>
      </Link>
    </div>
  );
}

export default NotFound;
