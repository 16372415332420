import React from "react";
import { Outlet } from "react-router";
import TopNavBar from "../components/topNavBar/TopNavBar";
import Footer from "../components/footer/Footer";
import { Container } from "@mui/material";
import { OverallContainer } from "./styles";

function Layout() {
  return (
    <OverallContainer>
      <TopNavBar />
      <Container>
        <Outlet />
      </Container>
      <Footer />
    </OverallContainer>
  );
}

export default Layout;
