import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

function ActivePathButton({ path, buttonText }) {
  const theme = useTheme();
  const location = useLocation();
  const checkActivePath = (locationPath) => {
    return location.pathname === locationPath;
  };
  return (
    <NavLink
      to={path}
      style={{ textDecoration: "none" }}
    >
      {/* <Button
        sx={{
          // backgroundColor: checkActivePath(path) && theme.palette.primary.light,
          color: checkActivePath(path) && theme.palette.text.primary,
          borderBottom:
            checkActivePath(path) && `${theme.palette.text.primary} 1px solid`,
          transition: "background 1s, color 1s, borderBottom 1s",
        }}
        transition={"all 500ms ease-in"}
      >
        {buttonText}
      </Button> */}
      <Box
        sx={{
          color: theme.palette.text.primary,

          borderBottom: checkActivePath(path)
            ? `${theme.palette.text.primary} 1px solid`
            : "1px solid transparent",
          transition: "all 500ms",
          textTransform: "uppercase",
          textAlign: "center",
        }}
      >
        <Typography>{buttonText}</Typography>
      </Box>
    </NavLink>
  );
}

export default ActivePathButton;
