import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NotFound from "./pages/notFound404/NotFound";
import Main from "./pages/main/Main";
import About from "./pages/about/About";
import Layout from "./layouts/Layout";

function Router() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Layout />}
      >
        <Route
          path="/"
          element={<Main />}
        />

        <Route
          path="/about"
          element={<About />}
        />
      </Route>
      <Route
        path="/404"
        element={<NotFound />}
      />
      <Route
        path="*"
        element={<Navigate to="/404" />}
      />
    </Routes>
  );
}

export default Router;
