import React from "react";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box, Icon } from "@mui/material";
import { SocMedIconBox as SocMedStyledBox } from "./style";
import { Link } from "react-router-dom";

function SocMedIconBox({ url, icon, text }) {
  return (
    <SocMedStyledBox>
      <Link to={url}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px",
          }}
        >
          {icon}
          {text}
        </div>
      </Link>
    </SocMedStyledBox>
  );
}

function SocMedBox() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <SocMedIconBox
        url={"https://github.com/joonsunn"}
        icon={<GitHubIcon />}
        text={"Github"}
      />
      <SocMedIconBox
        url={"https://my.linkedin.com/in/joon-sunn-foo-764155a3"}
        icon={<LinkedInIcon />}
        text={"LinkedIn"}
      />
    </Box>
  );
}

export default SocMedBox;
