import React from "react";
import { StyledFooter } from "./styles";

function Footer() {
  return (
    <StyledFooter>
      Site created by Joon Sunn. Copyleft. Optimised for dark theme.
    </StyledFooter>
  );
}

export default Footer;
