import logo from "./logo.svg";
// import "./App.css";
import { BrowserRouter } from "react-router-dom";
import MyThemeProvider from "./theme/theme";
import { ThemeSwitcher } from "./components/theme-switcher/ThemeSwitcher";
import { Container } from "@mui/material";
import Router from "./routes";

function App() {
  return (
    <BrowserRouter>
      <MyThemeProvider>
        <Router />
      </MyThemeProvider>
    </BrowserRouter>
  );
}

export default App;
