export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          // backgroundColor: theme.palette.primary.dark,
        },
      },
    },
  };
}
