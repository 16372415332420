import React from "react";
import { StyledCard } from "./styles";
import { CardContent, Chip } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

function ProjectCards({ project }) {
  const theme = useTheme();
  // const project = {
  //     title: "Masak Apa?",
  //     screenshot: "masak-apa.png",
  //     url: "https://masak-apa.fly.dev/",
  //     description:
  //       "Web app to randomly choose what to cook based on chosen main ingredient.",
  //     techUsed:
  //       "React.js, express, node, Javascript, Postgresql, Bootstrap, Redux, React Router",
  //     projectId: 6,
  // }
  const { title, url, screenshot, description, techUsed: tech } = project;
  return (
    <StyledCard>
      <Link
        to={url}
        style={{ textDecoration: "none", color: "unset" }}
      >
        <CardContent
          sx={{ display: "flex", gap: "15px", flexDirection: "column" }}
        >
          <Box
            sx={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              height: "5rem",
              color: theme.palette.secondary.main,
              a: {
                color: theme.palette.secondary.main,
              },
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              // width: "100%",
            }}
          >
            <Link to={url}>
              <span>{title}</span>
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              alt="project screenshot"
              src={`/screenshots/${screenshot}`}
              height={"200rem"}
              style={{ outline: `1px solid grey` }}
            />
          </Box>
          <Box
            sx={{
              fontStyle: "italic",
            }}
          >
            <span style={{ whiteSpace: "pre-line" }}>{description}</span>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "4px",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            Tech Used:
            {tech.split(",").map((techItem) => (
              <Chip label={techItem} />
            ))}
          </Box>
        </CardContent>
      </Link>
    </StyledCard>
  );
}

export default ProjectCards;
