import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { StyledCard } from "../../components/containers/styles";

import { MainContainer } from "../../layouts/styles";
import ProjectCards from "../../components/containers/ProjectCards";
import { projectsList } from "../../data/portfolioList";

function Main() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const tablet = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <MainContainer sx={{}}>
      <Typography
        variant="h1"
        sx={{
          // backgroundcolor: "primary",
          background: `linear-gradient(90deg, rgba(242,76,0,1) 0%, rgba(249,199,132,1) 70%, rgba(72,86,150,1) 100%);`,
          // backgroundSize: "100%",
          // backgroundRepeat: "repeat",
          // backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textAlign: "center",
          // maxWidth: "800px",
          fontWeight: 900,
          fontSize: "76px",
          padding: !mobile && "25px 0",
        }}
      >
        Joon Sunn's Projects
      </Typography>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          // style={{
          //   display: "flex",
          //   flexDirection: "row",
          //   gap: 25,
          //   flexWrap: "wrap",
          //   justifyContent: "center",
          // }}
          style={{
            display: "grid",
            // flexDirection: "row",
            gap: "2rem",
            gridTemplateColumns: mobile
              ? "1fr"
              : tablet
              ? "1fr 1fr"
              : "1fr 1fr 1fr",
            // flexWrap: "wrap",
            // justifyContent: "center",
          }}
        >
          {projectsList.map((project) => (
            <ProjectCards project={project} />
          ))}
        </div>
      </div>
    </MainContainer>
  );
}

export default Main;
