import { Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import useTheme from "@mui/material/styles";

export const StyledNav = styled("nav")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  padding: "1rem 0",
  backgroundColor: theme.palette.background.paper,
  width: "100%",
  gap: "20px",
  // position: "sticky",
}));

export const SocMedIconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // gap: "5px",
  color: theme.palette.text.primary,
  a: {
    color: theme.palette.text.primary,
  },
}));
